import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Assuming you have a CSS file for styling

function Navbar() {
    return (
        <nav>
            <div className="navbar-container">
                <NavLink 
                    to="/" 
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    Home
                </NavLink>
                <NavLink 
                    to="/projects" 
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    Projects
                </NavLink>
                <NavLink 
                    to="/about" 
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    About
                </NavLink>
                <NavLink 
                    to="/contact" 
                    className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}>
                    Contact
                </NavLink>
            </div>
        </nav>
    );
}

export default Navbar;