import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import api from '../api';

function Dashboard() {
  const { authUser, logout } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();  // Use the useNavigate hook

  // Fetch users from the API
  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      alert('Access denied or an error occurred while fetching users.');
    }
  };

  // Handle logout and redirect to landing page
  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');  // Redirect to the landing page after logout
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Handle user deletion
  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await api.delete(`/users/${userId}`);
        fetchUsers();  // Refresh the list after deletion
      } catch (error) {
        alert('Failed to delete user.');
      }
    }
  };

  // Handle making a user admin
  const handleMakeAdmin = async (userId) => {
    if (window.confirm('Are you sure you want to make this user an admin?')) {
      try {
        await api.put(`/users/${userId}`, { is_admin: true });
        fetchUsers();  // Refresh the list after updating the user
      } catch (error) {
        alert('Failed to make user an admin.');
      }
    }
  };

  // Fetch users on component mount
  useEffect(() => {
    if (authUser && authUser.is_admin) {
      fetchUsers();
    }
  }, [authUser]);

  return (
    <div>
      <h2>Dashboard</h2>
      <p>Welcome, {authUser.username}!</p>
      <button onClick={handleLogout}>Logout</button>

      {authUser.is_admin && (
        <div>
          <h3>All Users</h3>
          <table border="1">
            <thead>
              <tr>
                <th>ID</th>
                <th>Username</th>
                <th>Admin</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.username}</td>
                  <td>{user.is_admin ? 'Yes' : 'No'}</td>
                  <td>
                    <button onClick={() => handleDelete(user.id)}>Delete</button>
                    {!user.is_admin && (
                      <button onClick={() => handleMakeAdmin(user.id)}>Make Admin</button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!authUser.is_admin && (
        <p>You do not have permission to view the users list.</p>
      )}
    </div>
  );
}

export default Dashboard;