import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Navbar from './components/Navbar';
import PrivateRoute from './components/PrivateRoute';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import LandingPage from './pages/LandingPage';
import Projects from './pages/Projects';
import TagManager from 'react-gtm-module';

function App() {
  // Initialize Google Tag Manager when the app loads
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-TQQP7N66' // Replace with your GTM ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Navbar />  {/* Navbar added here */}
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/projects" element={<Projects />} />

          {/* Private Routes */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />

          {/* Default Route */}
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
