import React from 'react';
import { useNavigate } from 'react-router-dom';

function LandingPage() {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '100px' }}>
      <h1>Welcome to Our Application</h1>
      <p>Please login or register to continue.</p>
      <div>
        <button onClick={handleLogin} style={{ marginRight: '10px' }}>
          Login
        </button>
        <button onClick={handleRegister}>
          Register
        </button>
      </div>
    </div>
  );
}

export default LandingPage;