import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Projects() {
    const [projects, setProjects] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');

    useEffect(() => {
        fetchProjects();
        fetchUsers();
    }, []);

    const fetchProjects = async (userId = '') => {
        const response = await axios.get(`http://localhost:5000/api/projects`, {
            params: { user_id: userId },
            withCredentials: true
        });
        setProjects(response.data);
    };

    const fetchUsers = async () => {
        const response = await axios.get('http://localhost:5000/api/users', {
            withCredentials: true
        });
        setUsers(response.data);
    };

    const handleUserChange = (e) => {
        setSelectedUser(e.target.value);
        fetchProjects(e.target.value);
    };

    return (
        <div>
            <h1>Projects</h1>
            <select value={selectedUser} onChange={handleUserChange}>
                <option value="">All Users</option>
                {users.map(user => (
                    <option key={user.id} value={user.id}>{user.username}</option>
                ))}
            </select>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>User</th>
                    </tr>
                </thead>
                <tbody>
                    {projects.map(project => (
                        <tr key={project.id}>
                            <td>{project.id}</td>
                            <td>{project.name}</td>
                            <td>{project.username}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Projects;