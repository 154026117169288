import React, { createContext, useState, useEffect } from 'react';
import api from '../api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(null);

  const login = async (username, password) => {
    const response = await api.post('/login', { username, password });
    setAuthUser(response.data);
  };

  const logout = async () => {
    await api.post('/logout');
    setAuthUser(null);
  };

  const fetchUser = async () => {
    try {
      const response = await api.get('/user');
      setAuthUser(response.data);
    } catch {
      setAuthUser(null);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <AuthContext.Provider value={{ authUser, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};