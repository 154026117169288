import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';

function PrivateRoute({ children, requiredRole }) {
  const { authUser } = useContext(AuthContext);

  if (authUser === null) {
    // You can return a loading spinner here
    return null;
  }

  if (!authUser) {
    return <Navigate to="/login" />;
  }

  if (requiredRole && authUser.role !== requiredRole) {
    return <Navigate to="/unauthorized" />;
  }

  return children;
}

export default PrivateRoute;